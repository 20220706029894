import { useViewModel } from '@models/model';
import { Button, Col, Divider, Form, Input, Popconfirm, Row, Space, Spin, Typography, notification, Tabs, Alert, Select, Empty } from 'antd';
import TabPane from 'antd/es/tabs/TabPane';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { UserEditViewModel } from './user.edit.view.model';
import { useEffect, useState } from 'react';
import { ObjectLiteral } from '@utils/object.utils';
import { UnlockOutlined, WarningOutlined, KeyOutlined } from '@ant-design/icons';
import { HistoryView } from './users.history.view';
import { RootModel } from '@models/root-model';

const UserEditView: React.FC = observer(() => {
    const { t } = useTranslation();
    const { id: userId } = useParams();
    const [form] = Form.useForm();

    const navigate = useNavigate();
    const [activeTab, setActiveTab] = useState('data');
    const [changedFields, setChangedFields] = useState<ObjectLiteral>({});
    const [notifyer, contextHolder] = notification.useNotification();
    const showSuccessNotification = (title: string, description: string) => {
        notifyer.success({
            message: title,
            description: description
        });
    };
    const showErrorNotification = (title: string, description: string) => {
        notifyer.error({
            message: title,
            description: description
        });
    };

    const onClickCancel = () => {
        navigate('/users-manage');
    };

    const viewModel = useViewModel(
        () =>
            new UserEditViewModel({
                t: t,
                userId: userId,
                showSuccess: showSuccessNotification,
                showError: showErrorNotification,
                onClose: onClickCancel
            })
    );

    useEffect(() => {
        form.resetFields();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [viewModel.user, viewModel.institution]);

    const onErrorFormValidation = (errorsInfo: any) => {
        showErrorNotification(t('common.error.save'), t('common.check_fields'));
    };

    const onTabChange = (key: string) => {
        setActiveTab(key);
    };

    const onFormValueChange = (changed: any) => {
        setChangedFields({ ...changedFields, ...changed });
    };

    const onSuccessFormValidation = async () => {
        await viewModel.onSave(changedFields);
    };

    const isRoleChangeDisabled = () => {
        return viewModel.user.email === RootModel.authModel.profile?.email;
    };

    const editUser = () => {
        return (
            <div>
                {contextHolder}
                <br />
                <Spin spinning={viewModel.isLoading}>
                    {viewModel.user && viewModel.user.is_active === false && (
                        <div>
                            <Alert
                                message={t('users.content.active.locked')}
                                description={t('users.content.active.locked_description')}
                                type="warning"
                                showIcon
                            />
                            <br />
                        </div>
                    )}

                    <div className="edit-content-view">
                        <Form
                            form={form}
                            initialValues={{ ...viewModel.user, institution_id: viewModel.institution?.id }}
                            layout="horizontal"
                            onValuesChange={onFormValueChange}
                            onFinish={onSuccessFormValidation}
                            onFinishFailed={onErrorFormValidation}
                        >
                            <Form.Item>
                                <Typography.Text>ID: </Typography.Text>
                                <Typography.Text strong copyable>
                                    {viewModel.user?.id || ''}
                                </Typography.Text>
                            </Form.Item>

                            <Form.Item name="email" label={t('users.columns.email')}>
                                <Input disabled />
                            </Form.Item>

                            <Form.Item name="name" label={t('users.columns.name')}>
                                <Input />
                            </Form.Item>
                            <Form.Item name="phone" label={t('users.columns.phone')}>
                                <Input />
                            </Form.Item>

                            <Form.Item name="role_id" label={t('users.columns.role')}>
                                <Select
                                    disabled={isRoleChangeDisabled()}
                                    options={[
                                        { value: 'admin', label: t('users.roles.admin') },
                                        { value: 'operator', label: t('users.roles.operator') }
                                    ]}
                                    showSearch
                                    optionFilterProp="children"
                                    filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                    allowClear
                                    placeholder={t('common.sorting.not_selected')}
                                />
                            </Form.Item>

                            <Form.Item name="institution_id" label={t('users.content.inst.title')}>
                                <Select
                                    showSearch
                                    allowClear
                                    notFoundContent={<Empty description={''} />}
                                    placeholder={t('users.content.inst.placeholder')}
                                    onSearch={(value: string) => viewModel.searchInstitutions(value)}
                                    loading={viewModel.isInstitutionLoading}
                                    filterOption={false}
                                >
                                    {viewModel.institutions.map((item) => (
                                        <Select.Option value={item.id} key={item.id}>
                                            {item.short_name ? `${item.short_name} - ${item.name}` : item.name}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Form>
                    </div>

                    <Divider>
                        <WarningOutlined className="users-edit-danger-divider-icon" />
                        <Typography.Text className="users-edit-danger-divider-text"> {t('common.danger')}</Typography.Text>
                    </Divider>
                    <div className="edit-content-view">
                        <Space direction="horizontal" size="large" align="center">
                            <Popconfirm
                                title={t('users.content.reset_pwd.confirm')}
                                okText={t('users.content.reset_pwd.reset')}
                                cancelText={t('common.cancel')}
                                onConfirm={() => viewModel.resetUserPassword()}
                            >
                                <Button icon={<KeyOutlined />}>{t('users.content.reset_pwd.title')}</Button>
                            </Popconfirm>
                            <Popconfirm
                                title={t('users.content.active.confirm')}
                                okText={viewModel.user.is_active === false ? t('users.content.active.unlock') : t('users.content.active.lock')}
                                cancelText={t('common.cancel')}
                                onConfirm={() => viewModel.toggleUserActiveStatus()}
                            >
                                <Button className="users-edit-delete-account-button" icon={<UnlockOutlined />}>
                                    {viewModel.user.is_active === false ? t('users.content.active.unlock') : t('users.content.active.lock')}
                                </Button>
                            </Popconfirm>
                            <Popconfirm
                                title={t('users.content.delete.confirm')}
                                okText={t('users.content.delete.delete')}
                                cancelText={t('common.cancel')}
                                onConfirm={() => viewModel.deleteUser()}
                            >
                                <Button className="users-delete-account-button" icon={<UnlockOutlined />}>
                                    {t('users.content.delete.delete')}
                                </Button>
                            </Popconfirm>
                        </Space>
                    </div>
                </Spin>
                <br />
            </div>
        );
    };

    return (
        <div>
            {contextHolder}
            <br />
            <Tabs
                tabBarExtraContent={{
                    right: (
                        <Row gutter={12} justify="end" align="middle">
                            <Col>
                                <Button onClick={onClickCancel}>{t('common.cancel')}</Button>
                            </Col>
                            {['data'].includes(activeTab) && (
                                <Col>
                                    <Button type="primary" onClick={form.submit} loading={viewModel.isLoading} disabled={viewModel.isLoading}>
                                        {t('common.save')}
                                    </Button>
                                </Col>
                            )}
                        </Row>
                    )
                }}
                onChange={onTabChange}
            >
                <TabPane tab={t('users.edit.data.title')} key={'data'}>
                    {editUser()}
                </TabPane>

                <TabPane tab={t('users.edit.history.title')} key={'history'}>
                    {
                        <HistoryView
                            isLoading={viewModel.isHistoryLoading}
                            items={viewModel.historyItems}
                            itemsTotal={viewModel.historyTotal}
                            currentItem={viewModel.historyCurrentItem}
                            loadNextItems={() => viewModel.fetchNextHistoryItemsPage()}
                            loadHistoryItem={(historyId: number) => viewModel.onSelectHistoryItem(historyId)}
                        />
                    }
                </TabPane>
            </Tabs>
        </div>
    );
});

export default UserEditView;
